import React from 'react';
import styled from "styled-components";
import SideMenu from "../components/Menus/SideMenu";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const ContentWrapper = styled.div`
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ContentBox = styled.div`
  background-color: #fff;
  padding: 30px;
  box-sizing: content-box;
  flex: 1;
`

function SidebarLayout({
                           title,
                           children,
                           menuItems,
                           backLink,
                           header
                       }) {
    return (
        <Wrapper>
            <SideMenu
                title={title}
                backLink={backLink}
            >{menuItems}</SideMenu>
            <ContentWrapper>
                {header}
                <ContentBox>
                    {children}
                </ContentBox>
            </ContentWrapper>
        </Wrapper>
    );
}

export default SidebarLayout;
