import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const MenuWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  //border-right: solid 1px;
  border-color: lightgray;
  background: #161616;
  color: #fff;

  &.expanded {
    width: 280px;
  }
`;

const NavLinkStyled = styled(NavLink)`
  display: block;
  padding: 0 10px;
  height: 40px;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  transition: ease-in 0.2s;
  color: #fff;
  margin-top: .5rem;

  &:hover {
    .inner-wrapper {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .inner-wrapper {
    //background-color: #fff;
    border-radius: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: ease-in 0.3s;
  }
  
  &.active .inner-wrapper {
    background-color: #723DEC;
    color: #fff;
  }
  
  & svg {
    fill: #fff;
  }
`;

const MenuItemStyled = styled.div`
  display: block;
  padding: 0 10px;
  height: 40px;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  
  .inner-wrapper {
    border-radius: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.active .inner-wrapper {
    background-color: #016DFF;
    width: 100%;
  }
`;

const MenuItemIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  display: none;
  justify-content: flex-start;

  &.center {
    justify-content: center;
  }

  &.expanded {
    display: flex;
    padding-left: 20px;
  }
`;

const HeaderWrapper = styled.div`
  display: block;
  height: 60px;

  .inner-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const HeaderTitle = styled.div`
  padding: 10px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  align-self: center;
  justify-self: center;
  font-size: 18px;
`

// const HeaderPrefixSuffix = styled.div`
//   padding: 10px 20px;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// `
//
// const EmptyIconSpace = styled.div`
//   height: 24px;
//   width: 24px;
// `

const Header = ({ title, backLink, expanded }) => {
  // const history = useHistory()
  return (
    <HeaderWrapper>
      <div className="inner-wrapper">
        {expanded && <><HeaderTitle>{title}</HeaderTitle></>}
      </div>
    </HeaderWrapper>
  )
}

export const SideMenuItem = ({
                               title,
                               to,
                               icon,
                               onClick,
                               expanded = true,
                               alignTitle = "left",
                               exact = false
                             }) => {

  if (to) {
    return (
      <NavLinkStyled exact={exact} to={to}>
        <div className="inner-wrapper">
          <MenuItemIconWrapper>
            {icon && React.createElement(icon, { size: 24 })}
          </MenuItemIconWrapper>
          <Title className={`${alignTitle} ${expanded && "expanded"}`}>{title}</Title>
        </div>
      </NavLinkStyled>
    );
  }
  return <MenuItemStyled onClick={onClick}>
    <div className="inner-wrapper">
      <MenuItemIconWrapper>
        {icon && React.createElement(icon, { size: 24, color: "#fff" })}
      </MenuItemIconWrapper>
      {expanded && <Title className={`${alignTitle}`}>{title}</Title>}
    </div>
  </MenuItemStyled>;
};

function SideMenu({
                    children,
                    title = "",
                    backLink
                  }) {

  const [expanded] = useState(true);

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { expanded: expanded });
    }
    return child;
  });

  return (
    <MenuWrapper className={expanded ? "expanded" : ""}>
      {/*<SideMenuItem title="Склад" alignTitle="center" icon={ArrowLeft} onClick={() => setState(!state)}/>*/}
      <Header title={title} expanded={expanded} backLink={backLink} />
      {/*<SideMenuItem icon={Navigation} onClick={() => setExpand(!expanded)} />*/}
      {childrenWithProps}
      {/*<Button primary>Сохранить</Button>*/}
    </MenuWrapper>
  );
}

export default SideMenu;
