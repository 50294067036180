import store from "./redux/store";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import Routes from "./routes";
import * as system from "reakit-system-bootstrap";
import {Provider as ReakitProvider} from "reakit";
import 'antd/dist/antd.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {
    return (
        <Router>
            <ToastContainer />
            <Provider store={store}>
                <ReakitProvider unstable_system={system}>
                    <Routes />
                </ReakitProvider>
            </Provider>
        </Router>
    );
}

export default App;
