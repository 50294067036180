import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {useSelector} from "react-redux";

function ProtectedRoute({component: Component, ...restOfProps}) {
    // const [loading, setLoading] = useState(false)
    let is_authed = useSelector(state => state.user.is_authed)
    // const history = useHistory()
    // useEffect(async () => {
    //     if (is_authed) {
    //         try {
    //             await APIs.getUserAuth()
    //         } catch (e) {
    //             history.push("/signin")
    //         }
    //     }
    // }, [])

    // if (loading) {
    //     return <h3>Loading...</h3>
    // }


    return (
        <Route
            {...restOfProps}
            render={(props) =>
                is_authed ? <Component {...props} /> : <Redirect to="/signin" />
            }
        />
    );
}

export default ProtectedRoute;