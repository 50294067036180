import React, {Component} from 'react';
import {Table} from "antd";
import {APIs} from "../../apis";
import moment from "moment";

class Hunt extends Component {

    state = {
        data: [],
        page: 1,
        limit: 10,
        count: 0,
        loading: false
    }

    columns = [
        {
            title: 'Discord ID',
            dataIndex: 'discord_id',
            key: 'discord_id',
        },
        {
            title: 'Lottery number',
            dataIndex: 'lottery_number',
            key: 'lottery_number',
        },
        {
            title: 'Wallet address',
            dataIndex: 'wallet_address',
            key: 'wallet_address',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record, index) => moment(text).format("DD-MM-YYYY HH:mm:SS")
        },
    ];

    getData = async (page = this.state.page, limit = this.state.limit) => {
        try {
            this.setState({loading: true})
            const {data} = await APIs.getHunts({page: page, limit: limit})
            this.setState({loading: false, data: data.items, count: data.count})
        } catch (e) {

        } finally {
            this.setState({loading: false})
        }
    }

    componentDidMount() {
        this.getData()
    }

    setPage = (pagination) => {
        let { current, pageSize } = pagination
        this.setState({ page: current, limit: pageSize })
        this.getData(current, pageSize)
    }


    render() {
        const {page, limit, count} = this.state
        return (
            <div>
                <Table onChange={this.setPage} dataSource={this.state.data} columns={this.columns}
                       pagination={{pageSize: limit, current: page, total: count, size: "default"}} />
            </div>
        );
    }
}

export default Hunt;