import React, {useEffect, useState} from 'react';
import {Switch, Route, useHistory} from "react-router-dom"
import Login from "../screens/signIn";
import ProtectedRoute from "./ProtectedRoute";
import SidebarLayout from "../layouts/SidebarLayout";
import {SideMenuItem} from "../components/Menus/SideMenu";
import Hunt from "../screens/hunt";
import {APIs} from "../apis";

function Routes(props) {
    const history = useHistory()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        APIs.getUserAuth()
            .then(() => history.push("/"))
            .catch(() => history.push("/signin"))
            .finally(() => setLoading(false))
    })

    if (loading) {
        return null
    }

    return (
        <Switch>
            <Route path="/signin" component={Login} />
            <ProtectedRoute path="/">
                <SidebarLayout title="EggHeadz Dashboard" menuItems={[
                    // <SideMenuItem exact title="Discord" to="/" icon={DiscordIcon} />,
                    <SideMenuItem title="Hunt" to="/" />
                ]}>
                    <Switch>
                        {/*<Route exact path="/" component={Discord} />*/}
                        <Route path="/" component={Hunt} />
                    </Switch>
                </SidebarLayout>
            </ProtectedRoute>
        </Switch>
    );
}

export default Routes;