import React from 'react';
import styled from "styled-components";
import SignInForm from "./SignInForm";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

function Login(props) {
    console.log(process.env.REACT_APP_API_BASE_URL)
    return (
        <Wrapper>
            <SignInForm />
        </Wrapper>
    );
}

export default Login;