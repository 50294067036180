import axios from "axios";

axios.defaults.withCredentials = true

const Api = axios.create({
    baseURL: "https://dashboard.eggheadz.io/api",
    withCredentials: true
});

export const APIs = {
    signIn(payload) {
        return Api.post('/auth/signin', payload)
    },
    getUserAuth() {
        return Api.get('/auth/is_authed');
    },
    getHunts({ page, limit }) {
        return Api.get(`/admin/hunt?page=${page}&limit=${limit}`)
    }
}

export default Api;
