import React from 'react';
import {
    unstable_Form as Form,
    unstable_FormInput as FormInput,
    unstable_FormLabel as FormLabel,
    unstable_FormMessage as FormMessage,
    unstable_FormSubmitButton as FormSubmitButton,
    unstable_useFormState as useFormState,
} from "reakit/Form";
import styled from "styled-components";
import {APIs} from "../../apis";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {signIn} from "../../redux/actions/user";
import {useHistory} from "react-router-dom";

const Wrapper = styled.div`
  width: 300px;
`

const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

function SignInForm(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const form = useFormState({
        values: {
            username: "", password: ""
        },
        onValidate: (values) => {
            let errors = {};
            Object.keys(values).forEach(key => {
                if (!values[key]) {
                    errors = { [key]: "Required"}
                    throw errors;
                }
            })
        },
        onSubmit: async (values) => {
            try {
                const user = await APIs.signIn({username: values.username, password: values.password})
                dispatch(signIn(user.data))
                history.push("/")
            } catch (e) {
                toast.error(e.toString())
            }
        },
    });
    return (
        <Wrapper>
            <Form {...form}>
                <FormLabel {...form} name="username">
                    Username
                </FormLabel>
                <FormInput required {...form} name="username" placeholder="" />
                <FormMessage {...form} name="username" />
                <FormLabel {...form} name="password">
                    Password
                </FormLabel>
                <FormInput required type="password" {...form} name="password" placeholder="" />
                <FormMessage {...form} name="password" />
                <FlexBox>
                    <FormSubmitButton {...form}>Sign in</FormSubmitButton>
                </FlexBox>
            </Form>
        </Wrapper>
    );
}

export default SignInForm;