import { handleActions } from "redux-actions";

const initialState = {
  id: null,
  fullname: "",
  username: "",
  email: "",
  is_authed: false
};

const handlers = {
  SIGN_IN: (state, { payload }) => {
    return {
      ...state, ...payload
    };
  },
};

export default handleActions(handlers, initialState);
